body .ant-layout-header {
    background: #fff;
    padding-left: 30px;
}

.header-left{
    width: 600px !important;
    float: left;
}

.trigger{
    font-size: 20px;
    cursor: pointer;
}
.logo{
    height: 64px;
    line-height: 64px;
    margin-left: 20px;
    display: inline-block;
}
.logo .logo-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    float: left;
}
.logo img{
    width: 50px;
    height: 50px;
}
.logo span{
    font-size: 18px;
    font-weight: 700;
}

.header-right{
    float: right;
}

.header-icon{
    font-size: 22px;
    color: #7d7d7d;
    cursor: pointer;
}
.ant-dropdown-link{
    margin-left: 6px;
    cursor: pointer;
}
