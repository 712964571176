.login-wrap{
    width: 100%;
    height: 100%;
    background: url("../../assets/login_bg.jpg") center no-repeat #F1F2F6;
    position: relative;
}

.login-main{
    width: 400px;
    height: 480px;
    background-color: #fff;
    border-radius: 6px;
    padding: 24px 16px;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -200px;
    margin-top: -220px;
    box-shadow: 0 0 20px #ececec;
}
.login-main h2{
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin: 30px 0 40px 0;
}
.login-main .error-text{
    float: right;
    color: #D2485F;
}

.login-main .login-btn{
    width: 100%;
}
.login-main .copyright{
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -200px;
}
