body{
    width: 100% !important; /*解决Modal弹窗设置calc(100% - 17px)导致内容移位的问题*/
    overflow: hidden;
}
body .ant-layout-content{
    min-height: auto;
}
body .ant-table-thead > tr > th{
    font-weight: 600;
}

/* table头部背景色 */
/*body .ant-table-thead>tr>th{*/
    /*background: #e6f7ff;*/
/*}*/

/* 表格偶数行背景色 */
body .ant-table-tbody .ant-table-row.even{
    background: #fafafa;
}

.page-content{
    padding: 24px;
}

.page-container{
    padding: 24px 24px 0;
}
.main-wrap{
    min-height: 780px;
    /*min-height: 100%;*/
    padding: 24px;
    background-color: #fff;
    overflow: hidden;
}

/* 分页 */
.pagination-wrap{
    margin-top: 10px;
}
.pagination{
    float: right;
}

/* 弹窗 */
.customLayer{
    width: 1300px;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 11;
}
.customLayer .layer-header{
    padding: 16px 24px;
    border-bottom: 1px solid #EBEBEB;
    overflow: hidden;
}
.customLayer .layer-header .layer-header-title{
    font-weight: 600;
    float: left;
}
.customLayer .layer-header .close{
    cursor: pointer;
    float: right;
}
.customLayer .layer-header .close:hover{
    color: #1890ff;
}

.customLayer .layer-body{
    padding: 24px;
}

.customLayer .layer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 16px 24px;
    border-top: 1px solid #EBEBEB;
}
.layer-footer-btn{
    text-align: center;
}
.layer-footer-btn button{
    margin: 0 10px;
}

/* 弹窗遮罩层 */
.mark{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.65);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
}

/* 弹窗显示效果 */
.layer-enter {
    opacity: 0;
    transform: scale(0.9);
}
.layer-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.layer-exit {
    opacity: 1;
}
.layer-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.tabFixed{
    position: fixed;
    top: 64px;
    z-index: 1;
}

/* 自定义列表 */
.customList-layer{
    width: 720px;
    /*height: 315px;*/
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    padding: 24px 24px 0;
    border: 1px solid #eee;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 9999999;
}
.customList-layer .customList-head{
    font-size: 16px;
    font-weight: 600;
    color: #1890FF;
    overflow: hidden;
}
.customList-layer .customList-head span{
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    float: right;
}
.customList-layer .customList-content{
    padding: 20px 0;
}
.customList-layer .customList-content .ant-checkbox-group-item{
    width: 150px;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.customList-layer .customList-footer{
    padding: 15px 0;
    border-top: 1px solid #ececec;
}
/* 自定义列表 end*/

.help-text{
    color: #ccc;
    margin-left: 20px;
}
